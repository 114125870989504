import React from "react"

const BopsFaqs = () => (
  <div class="max-w-3xl md:max-w-full sm:max-w-full xs:max-w-full mx-auto xs:mx-4 sm:mx-4 md:mx-4 p-4 m-4 bg-white shadow rounded">
    <div class="max-w-screen-xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
      <div class="max-w-3xl mx-auto">
        <h2
          id="bop-faqs"
          class="text-center text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10"
        >
          Basics of Pistol Shooting FAQs
        </h2>
        <div class="text-center">
          <small>
            Don't see your question here? Check our{" "}
            <a href="#general-faq" class="text-red-700 hover:text-red-500">
              General FAQs
            </a>
            , or reach out to us via{" "}
            <a
              href="tel:979-429-3414"
              class="text-red-700 hover:text-red-500"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong> phone</strong>
            </a>{" "}
            or{" "}
            <a
              href="mailto:support@savageoutdoordefense.com"
              class="text-red-700 hover:text-red-500"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>email</strong>
            </a>
            !
          </small>
        </div>
        <div class="mt-6 border-t-2 border-gray-200 pt-6">
          <dl>
            <div>
              <dt class="text-lg leading-7">
                <span class="font-medium text-gray-900">
                  What do I need to bring for the Basics of Pistol Shooting
                  course?
                </span>
              </dt>
              <dd class="mt-2 pr-12">
                <p class="text-base leading-6 text-gray-500">
                  It is recommended that you bring your own handgun to use,
                  however we do have limited handgun rentals for $15 on a
                  first-come, first-serve basis. You will also need ammunition
                  for your handgun. If you plan to rent a handgun, please let us
                  know so that we can inform you of which ammo to purchase. Eye
                  and ear protection are provided, however feel free to bring
                  your own.
                </p>
              </dd>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    How much ammunition should I bring?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    We recommend bringing 150 rounds of ammunition. The shooting
                    qualification requires 60 rounds, and the extras may be used
                    for retests or malfunctions. We will also have a limited
                    amount of ammunition for sale if more is needed.
                  </p>
                </dd>
              </div>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    How long is the Basics of Pistol Shooting course?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    The BOPS course is approximately 9 hours long. This includes
                    8 hours of classroom time and 1 hour on the range.
                  </p>
                </dd>
              </div>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    Will there be breaks during the day?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    Yes! We schedule multiple breaks throughout the day, as well
                    as a 1-hour lunch break.
                  </p>
                </dd>
              </div>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    Do I need to pack a lunch?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    That is completely up to your discretion. We have a 1-hour
                    lunchbreak scheduled, but if you would like to eat in the
                    classroom and continue to go over materials, that is also an
                    option.
                  </p>
                </dd>
              </div>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    Do I need to own my own handgun to take this course?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    Absolutely not! However, it is highly recommended that you
                    bring your own handgun as we will be teaching you the
                    specifics of handling, loading, and cleaning your handgun.
                  </p>
                </dd>
              </div>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    Do I have to be an NRA member to take this course?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    No. Everyone is encouraged to take this course regardless of
                    their NRA affiliation or lack thereof.
                  </p>
                </dd>
              </div>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    Do you offer NRA memberships?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    Yes, we do! We offer all lengths of memberships, from 1 year
                    to lifetime memberships. Follow this link or pick up a paper
                    form at one of our classes!
                  </p>
                </dd>
              </div>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    If I can’t make the class I signed up for, can I reschedule?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    Absolutely, you are allowed a one-time free rescheduling as
                    long as you give us 72 hours or more of notice. If given
                    less than 72 hours’ notice you may still reschedule but may
                    be charged a $20 rescheduling fee. Be wary, this class is
                    not offered as frequently as some of our others. This means
                    if you need to reschedule you may have to wait awhile.
                  </p>
                </dd>
              </div>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    Can a spouse, child, or friend accompany me to the class,
                    even if they aren’t taking it?{" "}
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    No. Unfortunately due to the nature of this particular
                    course’s content. We do not allow non-students to sit in.
                  </p>
                </dd>
              </div>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    Do you allow kids to take this course?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    We do allow children aged 13+ to take this course. However,
                    we highly recommend that they take our Youth BOPS course
                    instead. It is essentially the same course, the material is
                    just presented in a way that is better suited for youth.
                  </p>
                </dd>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
)

export default BopsFaqs
