import React from "react"
import { Link } from "gatsby"
const LtcFaqs = () => (
  <div class="max-w-3xl md:max-w-full sm:max-w-full xs:max-w-full mx-auto xs:mx-4 sm:mx-4 md:mx-4 p-4 m-4 bg-white shadow rounded">
    <div class="max-w-screen-xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
      <div class="max-w-3xl mx-auto">
        <h2
          id="ltc-faqs"
          class="text-center text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10"
        >
          License to Carry (LTC) FAQs{" "}
        </h2>
        <div class="text-center">
          <small>
            Don't see your question here? Check our{" "}
            <a href="#general-faq" class="text-red-700 hover:text-red-500">
              General FAQs
            </a>
            , or reach out to us via{" "}
            <a
              href="tel:979-429-3414"
              class="text-red-700 hover:text-red-500"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong> phone</strong>
            </a>{" "}
            or{" "}
            <a
              href="mailto:support@savageoutdoordefense.com"
              class="text-red-700 hover:text-red-500"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>email</strong>
            </a>
            !
          </small>
        </div>
        <div class="mt-6 border-t-2 border-gray-200 pt-6">
          <dl>
            <div>
              <dt class="text-lg leading-7">
                <span class="font-medium text-gray-900">
                  What does LTC stand for?
                </span>
              </dt>
              <dd class="mt-2 pr-12">
                <p class="text-base leading-6 text-gray-500">
                  License To Carry, formerly known as a CHL or Concealed Handgun
                  License.
                </p>
              </dd>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    If I fail the LTC course, can I retake it?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    Absolutely! Here at Savage Outdoor & Defense, if you fail
                    the course, we allow up to two retakes free of charge. All
                    you cover is the range fee. We also provide you with one
                    hour of free private instruction to help you in the areas
                    that you struggled in.
                  </p>
                </dd>
              </div>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    Do I need to own a handgun to take the course?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    No, however it is recommended that you bring your own
                    handgun so that you can practice with the gun you plan to
                    use.
                  </p>
                </dd>
              </div>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    What if my firearm has a built-in laser, can I still use it
                    to qualify for my LTC?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    Yes, you can. We simply ask that you do{" "}
                    <strong>
                      <u>NOT</u>
                    </strong>{" "}
                    activate it at all during the qualification.&nbsp;
                  </p>
                </dd>
              </div>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    Can a spouse, child, or friend accompany me to the class,
                    even if they aren’t taking the course?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    Yes! We do allow others to sit in on the class, however they
                    must pay a $30 fee to reserve their seat. We also highly
                    recommend that no one under the age of 17 sits in on the
                    class, since the content can be graphic.
                  </p>
                </dd>
              </div>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    Do I need pistol experience to take the LTC course?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    No, however it is recommended as there is a shooting
                    proficiency test included in the course. Instructors are
                    also not allowed to assist in any way during the shooting
                    portion of the course. If you believe you will struggle, we
                    highly recommend looking into our{" "}
                    <Link
                      class="text-red-700 font-semibold underline"
                      to="/basics-of-pistol-shooting-bop"
                    >
                      Basics of Pistol Shooting
                    </Link>{" "}
                    class. This class is designed for beginners with minimal
                    handgun experience! Or check out our{" "}
                    <Link
                      class="text-red-700 font-semibold underline"
                      to="/texas-license-to-carry-ltc-course"
                    >
                      Private Instruction
                    </Link>
                    .
                  </p>
                </dd>
              </div>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    What do I need to bring for the LTC course?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    All you need is ammunition, a handgun, and your state issued
                    ID. If you have your own eye and ear protection, feel free
                    to bring it, but we supply those materials free of charge!
                    We also have handgun rental on a limited basis, so please
                    let us know in advance if you’d like to rent a handgun.
                  </p>
                </dd>
              </div>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    How much ammunition should I bring?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    We recommend bringing 100 rounds. You will need 50 rounds
                    for the shooting test and the extra 50 is in case of a
                    retest or malfunctions. If more ammunition is needed, we
                    have a limited supply for sale.
                  </p>
                </dd>
              </div>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    What is the cost to rent a handgun?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    Handgun rental is $15 on a first come, first serve basis. If
                    you fail the LTC course, you will be allowed to reuse the
                    rented handgun free of charge!
                  </p>
                </dd>
              </div>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    How long is the LTC course?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    The classroom portion of the course is approximately 4 hours
                    long, followed by a 30-minute examination. Following the
                    exam, we will have an optional walkthrough of how to submit
                    your application and supporting documents to the DPS. The
                    range portion typically takes about an hour. Add that up and
                    you should expect to spend 5.5-6 hours taking the course!
                  </p>
                </dd>
              </div>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    What are the LTC fees?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    There is a $65 classroom fee, $40 state licensing fee, and a
                    $10 fingerprinting fee. Overall, your cost will be $115.
                    Active-duty military, and those within 1 year of discharge,
                    can waive the state application fee.{" "}
                  </p>
                </dd>
              </div>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    How soon can I begin carrying?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    As soon as your official LTC ID card arrives in the mail.
                  </p>
                </dd>
              </div>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    How long does it take to receive my LTC ID card?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    The DPS has 60 days to issue a new license, and 45 days to
                    issue a renewal. However, recent reports say that, assuming
                    no priors, you will receive it within a month.
                  </p>
                </dd>
              </div>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    Do I need to be a Texas resident?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    No, however you must be a U.S. citizen, resident alien, or
                    permanent resident.
                  </p>
                </dd>
              </div>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    Do I have to carry the caliber I qualify with? Or the type
                    of handgun I qualify with?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    No, it does not matter what type of handgun or caliber you
                    qualify with, you may carry any caliber or type of handgun
                    you choose.
                  </p>
                </dd>
              </div>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    What is an LTC Form-100?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    It is documented proof of your completion of the LTC course
                    and must be submitted to the DPS to receive your official
                    LTC.
                  </p>
                </dd>
              </div>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    If I can’t make a class, can I reschedule?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    Absolutely, you are allowed a one-time free rescheduling as
                    long as you give us 72 hours or more of notice. If given
                    less than 72 hours’ notice you may still reschedule but may
                    be charged a $20 rescheduling fee.
                  </p>
                </dd>
              </div>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    How long do I have to apply for my license after I take the
                    course?{" "}
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    Your qualifications are good for exactly 2 years from the
                    date of your class.
                  </p>
                </dd>
              </div>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    What days do you offer the LTC course on?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    We can offer them on any day of the week, provided there is
                    a need on that day.
                  </p>
                </dd>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
)

export default LtcFaqs
