import React from "react"
import { Link } from "gatsby"
const GeneralFaqs = () => (
  <div class="max-w-3xl md:max-w-full sm:max-w-full xs:max-w-full mx-auto xs:mx-4 sm:mx-4 md:mx-4 p-4 m-4 bg-white shadow rounded">
    <div class=" mx-auto py-4 px-4 sm:py-16 sm:px-6 lg:px-8">
      <div class="max-w-3xl mx-auto">
        <h2
          id="general-faqs"
          class="text-center text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10"
        >
          General FAQs{" "}
        </h2>
        <div class="mt-6 border-t-2 border-gray-200 pt-6">
          <dl>
            <div>
              <dt class="text-lg leading-7">
                <span class="font-medium text-gray-900">
                  Which caliber handguns do you have for rent?
                </span>
              </dt>
              <dd class="mt-2 pr-12">
                <p class="text-base leading-6 text-gray-500">
                  For all of our courses, as well as private instruction, we
                  have .22LR, .38 special, 9mm, .40 S&W, and .45 ACP.
                </p>
              </dd>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    What is your refund policy?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    At Savage Outdoor & Defense we have a money-back guarantee.
                    If you cannot make your scheduled class, or one of the
                    following two, we will give you 100% of your money back,
                    minus any late cancellation fees and deposits.
                  </p>
                </dd>
              </div>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    Who gives you the authority to teach these courses?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    Shane is a certified NRA Pistol Instructor and Range Safety
                    Officer, as well as certified through the DPS to teach the
                    LTC Course.
                  </p>
                </dd>
              </div>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    When was Savage Outdoor & Defense founded?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    Savage Outdoor & Defense was founded in April of 2019. If
                    you’d like more information about what makes us who we are,
                    please check out our{" "}
                    <Link
                      class="text-red-700 font-semibold underline"
                      to="/about-us"
                    >
                      About Us
                    </Link>{" "}
                    page.
                  </p>
                </dd>
              </div>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    Do you have ammunition for sale for classes?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    Yes, we have limited amounts of ammunition for sale for use
                    in classes. However, we ask that you please try to bring the
                    appropriate amount you will need as our for-sale supply is
                    limited.
                  </p>
                </dd>
              </div>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    Do you offer any discounts for classes?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    Absolutely! Here is a list of the discounts we currently
                    offer:
                    <ul class="list-disc list-inside">
                      <li>
                        $5 off per person for referrals
                        <ul class="list-disc list-inside">
                          <li class="ml-6">
                            **If someone who has previously taken one of our
                            classes referred you, just add their name in the
                            notes section of your signup form to receive your
                            discount.
                          </li>
                        </ul>
                      </li>
                      <li>
                        $5 off per person when you register more than one person
                        at a time
                      </li>
                      <li>
                        $5 off active duty military, veterans, or first
                        responders
                      </li>
                    </ul>
                    **<strong>Note</strong>: These discounts cannot be stacked.
                    Our policy is one discount per person, per class**
                  </p>
                </dd>
              </div>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    Do you have Savage Outdoor & Defense merchandise for sale?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    Yes, we do! We have merchandise for sale at all of our
                    classes. For orders over $100, we offer free delivery within
                    25 miles of Bryan. For orders placed further away, we
                    require a shipping fee.
                  </p>
                </dd>
              </div>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    Can I purchase firearms through Savage Outdoor & Defense?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    Not at this time, but you will be able to in the near
                    future!
                  </p>
                </dd>
              </div>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    Which days are “business days”?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    We are open for business 7 days a week.
                  </p>
                </dd>
              </div>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    When will you be offering firearm services?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    We are hoping to be able to offer our full range of services
                    by the beginning of 2021! Check our social media for regular
                    updates!
                  </p>
                </dd>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
)

export default GeneralFaqs
