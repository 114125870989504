import React from "react"

const YouthBasicsFaqs = () => (
  <div class="max-w-3xl md:max-w-full sm:max-w-full xs:max-w-full mx-auto xs:mx-4 sm:mx-4 md:mx-4 p-4 m-4 bg-white shadow rounded">
    <div class="max-w-screen-xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
      <div class="max-w-3xl mx-auto">
        <h2
          id="youth-faqs"
          class="text-center text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10"
        >
          Youth Basics of Pistol Shooting FAQs
        </h2>
        <div class="text-center">
          <small>
            Don't see your question here? Check our{" "}
            <a href="#general-faq" class="text-red-700 hover:text-red-500">
              General FAQs
            </a>
            , or reach out to us via{" "}
            <a
              href="tel:979-429-3414"
              class="text-red-700 hover:text-red-500"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong> phone</strong>
            </a>{" "}
            or{" "}
            <a
              href="mailto:support@savageoutdoordefense.com"
              class="text-red-700 hover:text-red-500"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>email</strong>
            </a>
            !
          </small>
        </div>
        <div class="mt-6 border-t-2 border-gray-200 pt-6">
          <dl>
            <div>
              <dt class="text-lg leading-7">
                <span class="font-medium text-gray-900">
                  Do I need to accompany my child?
                </span>
              </dt>
              <dd class="mt-2 pr-12">
                <p class="text-base leading-6 text-gray-500">
                  No, but you may accompany them if you’d like.
                </p>
              </dd>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    Do I need to provide my child with a handgun?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    No, we do have handgun rentals for $15 on a first come first
                    serve basis. However, we do recommend that you do provide
                    one so that your child can become familiar with the handguns
                    you have in your home.
                  </p>
                </dd>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
)

export default YouthBasicsFaqs
