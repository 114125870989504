import React from "react"

const PrivateFaqs = () => (
  <div class="max-w-3xl md:max-w-full sm:max-w-full xs:max-w-full mx-auto xs:mx-4 sm:mx-4 md:mx-4 p-4 m-4 bg-white shadow rounded">
    <div class="max-w-screen-xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
      <div class="max-w-3xl mx-auto">
        <h2
          id="private-faqs"
          class="text-center text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10"
        >
          Private and Group Instruction FAQs
        </h2>
        <div class="text-center">
          <small>
            Don't see your question here? Check our{" "}
            <a href="#general-faq" class="text-red-700 hover:text-red-500">
              General FAQs
            </a>
            , or reach out to us via{" "}
            <a
              href="tel:979-429-3414"
              class="text-red-700 hover:text-red-500"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong> phone</strong>
            </a>{" "}
            or{" "}
            <a
              href="mailto:support@savageoutdoordefense.com"
              class="text-red-700 hover:text-red-500"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>email</strong>
            </a>
            !
          </small>
        </div>
        <div class="mt-6 border-t-2 border-gray-200 pt-6">
          <dl>
            <div>
              <dt class="text-lg leading-7">
                <span class="font-medium text-gray-900">
                  Do I need to bring my own handgun?
                </span>
              </dt>
              <dd class="mt-2 pr-12">
                <p class="text-base leading-6 text-gray-500">
                  No, however, since this lesson is tailored specifically to
                  your needs and wishes we highly recommend that you bring your
                  own handgun to learn how to properly handle and operate it.
                </p>
              </dd>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    What topics can be covered during private instruction?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    We can cover just about anything you’d like! These lessons
                    are designed to be tailored specifically to your needs. Let
                    us know what you would like to learn, and we can cover it!
                  </p>
                </dd>
              </div>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    Do you offer discounts for private instruction?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    Unfortunately, at this time we do not yet offer discounts on
                    our private instruction.
                  </p>
                </dd>
              </div>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    Do you offer in-home private instruction, or is it at the
                    range only?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    If you do not plan on live firing your handgun, and you
                    would prefer an at home lesson then we can absolutely
                    accommodate you!
                  </p>
                </dd>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
)

export default PrivateFaqs
