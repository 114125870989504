import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import IntroToPistolsFaqs from "../components/faqs/introToPistolsFaqs"
import GeneralFaqs from "../components/faqs/generalFaqs"
import LtcFaqs from "../components/faqs/ltcFaqs"
import BopsFaqs from "../components/faqs/BopsFaqs"
import YouthBasicsFaqs from "../components/faqs/YouthBasicsFaqs"
import PrivateFaqs from "../components/faqs/privateFaqs"
import FireArmServiceFaqs from "../components/faqs/FireArmServiceFaqs"
import FishingReelServicesFaqs from "../components/faqs/FishingReelServicesFaqs"

const FaqPage = () => (
  <Layout>
    <SEO title="FAQ | Savage Outdoor & Defense" />

    <div class="p-8 bg-gray-800">
      <div class="flex justify-center py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div class="max-w-full mx-auto">
          <h2 class="text-center text-3xl leading-9 font-extrabold text-blue-100 sm:text-4xl sm:leading-10 ">
            Frequently Asked Questions (FAQs)
          </h2>
          <div class="text-center text-blue-100 ">
            <small>
              Don't see your question here? Reach out to us via{" "}
              <a
                href="tel:979-429-3414"
                class="text-red-500 hover:text-red-500"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong> phone</strong>
              </a>{" "}
              or{" "}
              <a
                href="mailto:support@savageoutdoordefense.com"
                class="text-red-500 hover:text-red-500"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>email</strong>
              </a>
              !
            </small>
          </div>
          <div class="mt-6 border-t-2  border-gray-200 pt-6">
            <div class="align-middle md:grid md:grid-cols-2 md:gap-4 lg:grid lg:grid-cols-2 lg:gap-4 xl:grid xl:grid-cols-2 xl:gap-4 mx-auto xs:flex sm:flex justify-center">
              <ul class="list-none md:mx-auto lg:mx-auto xl:mx-auto">
                <li>
                  <a href="#general-faqs">
                    <h3 class="text-lg text-red-500 font-semibold underline mb-3">
                      General FAQs
                    </h3>
                  </a>
                </li>
                <li>
                  <a href="#ltc-faqs">
                    <h3 class="text-lg text-red-500 font-semibold underline mb-3">
                      LTC FAQs
                    </h3>
                  </a>
                </li>
                <li>
                  <a href="#bop-faqs">
                    <h3 class="text-lg text-red-500 font-semibold underline mb-3">
                      Basics of Pistol Shooting FAQs
                    </h3>
                  </a>
                </li>
                <li>
                  <a href="#youth-faqs">
                    <h3 class="text-lg text-red-500 font-semibold underline mb-3">
                      Youth Basics of Pistol Shooting FAQs
                    </h3>
                  </a>
                </li>
              </ul>
              <ul class="list-none md:mx-auto lg:mx-auto xl:mx-auto">
                <li>
                  <a href="#intro-pistol-faqs">
                    <h3 class="text-lg text-red-500 font-semibold underline mb-3">
                      Intro to Pistols Weekend FAQs
                    </h3>
                  </a>
                </li>
                <li>
                  <a href="#private-faqs">
                    <h3 class="text-lg text-red-500 font-semibold underline mb-3">
                      Private and Group Instruction FAQs
                    </h3>
                  </a>
                </li>
                <li>
                  <a href="#firearm-service-faqs">
                    <h3 class="text-lg text-red-500 font-semibold underline mb-3">
                      Firearm Services FAQs
                    </h3>
                  </a>
                </li>
                <li>
                  <a href="#fishing-faqs">
                    <h3 class="text-lg text-red-500 font-semibold underline mb-3">
                      Fishing Reel Services FAQs
                    </h3>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <GeneralFaqs />
    <LtcFaqs />
    <BopsFaqs />
    <YouthBasicsFaqs />
    <IntroToPistolsFaqs />
    <PrivateFaqs />
    <FireArmServiceFaqs />
    <FishingReelServicesFaqs />
  </Layout>
)

export default FaqPage
