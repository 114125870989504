import React from "react"

const IntroToPistolsFaqs = () => (
  <div class="max-w-3xl md:max-w-full sm:max-w-full xs:max-w-full mx-auto xs:mx-4 sm:mx-4 md:mx-4 p-4 m-4 bg-white shadow rounded">
    <div class="max-w-screen-xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
      <div class="max-w-3xl mx-auto">
        <h2
          id="intro-pistol-faqs"
          class="text-center text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10"
        >
          Intro to Pistols Weekend FAQs
        </h2>
        <div class="text-center">
          <small>
            Don't see your question here? Check our{" "}
            <a href="#general-faq" class="text-red-700 hover:text-red-500">
              General FAQs
            </a>
            , or reach out to us via{" "}
            <a
              href="tel:979-429-3414"
              class="text-red-700 hover:text-red-500"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong> phone</strong>
            </a>{" "}
            or{" "}
            <a
              href="mailto:support@savageoutdoordefense.com"
              class="text-red-700 hover:text-red-500"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>email</strong>
            </a>
            !
          </small>
        </div>
        <div class="mt-6 border-t-2 border-gray-200 pt-6">
          <dl>
            <div>
              <dt class="text-lg leading-7">
                <span class="font-medium text-gray-900">
                  What do I need to bring for the weekend?
                </span>
              </dt>
              <dd class="mt-2 pr-12">
                <p class="text-base leading-6 text-gray-500">
                  Yourself, your handgun, and your ammunition. Snacks and a
                  water bottle are also good ideas for our long day. Eye and ear
                  protection is provided, but you may bring your own if you
                  wish.
                </p>
              </dd>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    How much ammunition do I need?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    We recommend bringing 250 rounds of ammunition. The BOPS
                    course requires a minimum of 60 rounds, and the LTC course
                    requires a minimum of 50. The extra rounds are in case you
                    need a requalification.
                  </p>
                </dd>
              </div>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    Do I need to pack a lunch?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    This is 100% up to your discretion. We recommend bringing
                    snacks for consumption throughout the day, but we do have a
                    scheduled 1-hour lunch break.
                  </p>
                </dd>
              </div>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    How long are the days?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    The first day will be approximately 9 hours, including range
                    time, and the second day will be approximately 6 hours.
                  </p>
                </dd>
              </div>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    Do I have to take both classes in one weekend to get the
                    bundle pricing?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    You are required to sign up for them at the same time to
                    receive the discount, but we understand that schedules are
                    busy. If you cannot make both days of the class, we can
                    allow you to move one of the classes to a separate date as
                    long as there is availability, and the dates must fall
                    within a month of each other.
                  </p>
                </dd>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
)

export default IntroToPistolsFaqs
