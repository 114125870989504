import React from "react"

const FishingReelFaqs = () => (
  <div class="max-w-3xl md:max-w-full sm:max-w-full xs:max-w-full mx-auto xs:mx-4 sm:mx-4 md:mx-4 p-4 m-4 bg-white shadow rounded">
    <div class="max-w-screen-xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
      <div class="max-w-3xl mx-auto">
        <h2
          id="fishing-faqs"
          class="text-center text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10"
        >
          Fishing Reel Services FAQs
        </h2>
        <div class="text-center">
          <small>
            Don't see your question here? Check our{" "}
            <a href="#general-faq" class="text-red-700 hover:text-red-500">
              General FAQs
            </a>
            , or reach out to us via{" "}
            <a
              href="tel:979-429-3414"
              class="text-red-700 hover:text-red-500"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong> phone</strong>
            </a>{" "}
            or{" "}
            <a
              href="mailto:support@savageoutdoordefense.com"
              class="text-red-700 hover:text-red-500"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>email</strong>
            </a>
            !
          </small>
        </div>
        <div class="mt-6 border-t-2 border-gray-200 pt-6">
          <dl>
            <div>
              <dt class="text-lg leading-7">
                <span class="font-medium text-gray-900">
                  Which types of reels do you work on?
                </span>
              </dt>
              <dd class="mt-2 pr-12">
                <p class="text-base leading-6 text-gray-500">
                  We work on every type of reel available on the market! From
                  baitcast, to flipcast, to spincast, and everything in between.
                  Freshwater or saltwater, bring it to us and we’ll hook you up!
                </p>
              </dd>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    How long do fishing reel services take?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    If we receive the reel before noon we offer same day
                    turnaround. If we receive the reel after noon, it will be
                    ready by the end of the next business day.
                  </p>
                </dd>
              </div>
            </div>
            <div class="mt-6 border-t border-gray-200 pt-6">
              <div>
                <dt class="text-lg leading-7">
                  <span class="font-medium text-gray-900">
                    Why are saltwater services more expensive than freshwater
                    services?
                  </span>
                </dt>
                <dd class="mt-2 pr-12">
                  <p class="text-base leading-6 text-gray-500">
                    Saltwater services are more expensive for a couple reasons.
                    First, saltwater reels are typically larger and more heavy
                    duty than freshwater reels, and thus take more time and
                    product to clean or spool. Secondly, due to the high content
                    of salt in saltwater, these reels are subjected to
                    significantly more corrosion than freshwater reels.
                    Therefore, it is more difficult to clean and counteract the
                    corrosion than it is with freshwater reels.
                  </p>
                </dd>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
)

export default FishingReelFaqs
